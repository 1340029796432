import { graphql, useStaticQuery } from 'gatsby'
import * as React from 'react'
import Layout from '../components/Layout/Layout'
import Seo from '../components/Seo/Seo'
import { toggleCollapse } from '../scripts/scripts'

import '../scss/pages/faq.scss'
import Posts from '../components/Posts/Posts'
import InfoBox from '../components/InfoBox/InfoBox'

const FaqPage = () => {
  const data = useStaticQuery(query);
  const seo = data.wpPage.seo;
  const faq = data.allWpPost.edges;
 
  return (
    <Layout>
      <Seo title={seo.title} description={seo.metaDesc} keywords={seo.focuskw} pathname="/faq/" />
      <div className="page-top-section">
        <div className="container custom-container">
          <div className="row">
            <div className="col-md-12">
              <p className="small-header text-center mb-0 text-white">Faq</p>
              <h1 className="header-h1 text-center mt-15 mb-30 text-white">Najczęściej zadawane pytania</h1>
              <p className="paragraph text-center text-white font-light mb-0">Poznaj odpowiedzi na najbardziej nurtujące pytania na temat ubezpieczeń na życie do kredytu hipotecznego.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="faq section-padding">
        <div className="container custom-container">
          <div className="row">
            {faq.slice(0, 3).map((item, index) => {
              return  <div className="col-md-12">
                        <div className={`faq-box ${index < 2 ? 'mb-30': ''}`} onClick={toggleCollapse}>
                          <h2 className="header-h2 faq-title black-text-color show-more">{item.node.title}</h2>
                          <div className="faq-content d-none hidden-content" dangerouslySetInnerHTML={{ __html: item.node.content }}></div>
                        </div>
                      </div>
            })}
          </div>
        </div>
        <InfoBox />
        <div className="container custom-container">
          <div className="row">
            {faq.slice(3).map((item, index) => {
              return  <div className="col-md-12">
                        <div className="faq-box mb-30" onClick={toggleCollapse}>
                          <h2 className="header-h2 faq-title black-text-color show-more">{item.node.title}</h2>
                          <div className="faq-content d-none hidden-content" dangerouslySetInnerHTML={{ __html: item.node.content }}></div>
                        </div>
                      </div>
            })}
          </div>
        </div>
      </div>
      <Posts />
    </Layout>
  )
}

export default FaqPage

const query = graphql`
  {
    wpPage(slug: {eq: "faq"}) {
      seo {
        metaDesc
        title
        focuskw
      }
    }
    allWpPost(
      filter: {categories: {nodes: {elemMatch: {name: {eq: "FAQ"}}}}}
      sort: {fields: date, order: DESC}
    ) {
      edges {
        node {
          title
          content
        }
      }
    }
  }
`
